const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/lab-1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/lab-2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/lab-3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/lab-4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/lab-5.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4699.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/ATL-4.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/ATL5.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/ATL6.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4693.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4695.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4696.png",
];

export default data;
